<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.83"
    height="24.439"
    viewBox="0 0 26.83 24.439"
    :class="color"
  >
    <g transform="translate(0 7.525) rotate(-20)">
      <g class="a" transform="translate(0 0)">
        <path
          class="c"
          d="M22,18H0V11.5A2.5,2.5,0,0,0,2.5,9,2.5,2.5,0,0,0,0,6.5V0H22V6.5a2.5,2.5,0,1,0,0,5V18Z"
        />
        <path
          class="d"
          d="M 20.0004997253418 16 L 20.0004997253418 13.03088855743408 C 18.5197811126709 12.29329776763916 17.50029945373535 10.76352882385254 17.50029945373535 9 C 17.50029945373535 7.237447738647461 18.51984596252441 5.707804679870605 20.0004997253418 4.969820022583008 L 20.0004997253418 2 L 1.99999988079071 2 L 1.99999988079071 4.969111442565918 C 3.48071813583374 5.70670223236084 4.500199794769287 7.236471176147461 4.500199794769287 9 C 4.500199794769287 10.76255226135254 3.480653524398804 12.29219532012939 1.99999988079071 13.03017997741699 L 1.99999988079071 16 L 20.0004997253418 16 M 22.0004997253418 18 L 21.9995002746582 18 L -1.373290956507844e-07 18 L -1.373290956507844e-07 11.50020027160645 C 1.379889845848083 11.49919986724854 2.500199794769287 10.37806034088135 2.500199794769287 9 C 2.500199794769287 7.621389865875244 1.378609895706177 6.499800205230713 -1.373290956507844e-07 6.499800205230713 L -1.373290956507844e-07 0 L 22.0004997253418 0 L 22.0004997253418 6.499800205230713 C 20.62060928344727 6.500800132751465 19.50029945373535 7.621940135955811 19.50029945373535 9 C 19.50029945373535 10.3786096572876 20.62188911437988 11.50020027160645 22.0004997253418 11.50020027160645 L 22.0004997253418 17.99900054931641 L 22.0004997253418 18 Z"
        />
      </g>
      <path class="b" d="M0,0H8" transform="translate(7 6)" />
      <path class="b" d="M0,0H8" transform="translate(7 11.493)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "EventIcon",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.strom {
  .b {
    stroke: $strom;
    stroke-width: 2px;
  }

  .d {
    fill: $strom;
  }
}
.a,
.b {
  fill: none;
}
.b {
  transition: all ease 0.3s;
  stroke: $midnight;
  stroke-width: 2px;
}
.c,
.d {
  stroke: none;
}
.d {
  transition: all ease 0.3s;
  fill: $midnight;
}
</style>